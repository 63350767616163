<template>
  <div id="mission">
    <div class="container">
      <h1 v-scroll-reveal="{ delay: 400 }">Mission</h1>
      <section v-scroll-reveal="{ delay: 800 }" class="row-reverse">
        <picture>
          <img src="@/assets/cute-dog/png/cute pupies-14.png"/>
        </picture>
        <div class="content">
          <h3>Why hold a dog meme coin that does nothing, when you can hold $PUPPY and save a dog’s life?</h3>
          <p>When the pandemic began, Americans rushed to kennels, adopting dogs out of boredom. Now, these inconsiderate individuals are gambling with the lives of millions of dogs, deciding they no longer want them and returning them to shelters.</p>
          <p>Every day, dozens of dogs in America are put down. And in other countries their fate can be far worse, as they are often butchered to death, then eaten.</p>
          <p>The average cost to save the life of 1 dog is $122. The cost to rescue a sick dog from a backyard breeder, puppy mill, or foreign meat farm is even higher.</p>
        </div>
      </section>

      <section v-scroll-reveal="{ delay: 800 }">
        <picture>
          <img src="@/assets/cute-dog/png/cute pupies-01.png"/>
        </picture>
        <div class="content">
          <h3>We are calling on the crypto community to come together to end dog cruelty, with a fun for-purpose cryptocurrency that fights a serious problem. Together, we will save 164,000 puppies a year, while contributing building facilities to give them happy, loving homes.</h3>
        </div>
      </section>

      <ul class="list-style-none" v-scroll-reveal="{ delay: 800 }">
        <li>
          <router-link to="/how-to-buy">How To Buy Puppy Coin →</router-link>
        </li>
        <li class="button pancake-swap">
          <a target="_blank" href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0xb8d14376a2da9e500aa8fa2537351c0440e06c61">Buy on Pancakeswap →</a>
        </li>
        <li class="button">
          <a target="_blank" href="https://poocoin.app/tokens/0xb8d14376a2da9e500aa8fa2537351c0440e06c61">View Charts on PooCoin →</a>
        </li>
        <li class="button">
          <a target="_blank" href="#">Charity Wallet →</a>
        </li>
      </ul>
    </div>

    <socials/>
  </div>
</template>

<script>
import Socials from '@/components/Socials.vue'

export default {
  name: 'Mission',
  components: {
    Socials,
  },
}
</script>

<style scoped>
section {
  padding: 0 20px;
}
img {
  width: 170px;
}
h1 {
  margin-top: 80px;
  margin-bottom: 0;
}
h3, p {
  text-align: left;
  margin-left: auto;
  margin-right: auto;
}
h3 {
  margin-top: 8px;
}
ul {
  text-align: right;
  padding-right: 32px;
  margin: 32px auto 0;
  max-width: 300px;
}
li {
  padding-top: 8px;
}
a {
  font-size: 20px;
  padding-top: 12px;
  color: #fa9d20;
}
a:hover {
  color: black;
}

@media (min-width: 769px) {
  .row-reverse {
    flex-direction: row-reverse;
    margin-right: 0;
  }
  .row-reverse img {
    margin-left: 56px;
  }
  section {
    margin-top: 80px;
    padding: 0;
  }
  section:not(.socials) {
    display: flex;
  }
  .container {
    max-width: 960px;
  }
  .content {
    margin-left: 0;
    flex: 2;
  }
  ul {
    margin-top: 64px;
  }
  picture {
    flex: 1;
  }
  img {
    width: 300px;
    margin-right: 56px;
  }
}
</style>